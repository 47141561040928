import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB OHS’s 3×12/arm`}</p>
    <p>{`then,`}</p>
    <p>{`2017 CrossFit Regional Event 2`}</p>
    <p>{`21-15-9 reps for time of:`}</p>
    <p>{`Dumbbell Snatches`}</p>
    <p>{`Ring Dips`}</p>
    <p>{`or`}</p>
    <p>{`2017 CrossFit Regional Event 5`}</p>
    <p>{`21-15-9 reps for time of:`}</p>
    <p>{`Muscle Ups`}</p>
    <p>{`Single Arm OHS’s`}</p>
    <p><em parentName="p">{`*`}{`RX=80`}{`#`}{`DB for men, 55`}{`#`}{` DB for women.  Or use approximately 70% 1RM.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      